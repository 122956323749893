<template>
  <div :style="{ height: this.height }" class="scroll-box">
    <a-spin :spinning="loading">
      <a-timeline v-if="data.length > 0">
        <a-timeline-item v-for="history in data" :key="history.id">
          <p>
            <a-space>
              <span>{{ history.time }}</span>
              <span>{{ history.operateTypeDisplay }}</span>
              <a-tooltip>
                <template slot="title">
                  {{ history.operator }}
                </template>
                <span>
                  <span>({{ history.operator | operatorNameFilter }})</span>
                </span>
              </a-tooltip>
            </a-space>
          </p>
          <div
            v-if="['leave', 'to_origination', 'wear_qr', 'to_destination', 'to_dress_room', 'finish_dress',
                   'to_hall', 'completed', 'arrived', 'upload_death_picture', 'upload_video'].includes(history.operateType)"
            :style="{ display: 'inline-block'}"
          >
            <a-space>
              <span v-if="history.operateType === 'upload_death_picture'">上传遗像：</span>
              <span v-else-if="history.operateType === 'upload_video'">上传视频：</span>
              <span v-else-if="history.taskPic">上传图片：</span>

              <div v-if="history.operateType === 'upload_video'" style="display: flex;">
                <div v-for="(pic, index) in history.taskPic" :key="index" class="video">
                  <img src="/temp/logo.png" width="50px" height="50px" @click="showVideo(pic.url)">
                </div>
              </div>
              <div v-else class="custom-flex custom-flex-wrap">
                <img
                  v-show="history.taskPic"
                  v-for="(pic, index) in history.taskPic"
                  :key="index"
                  :src="pic.url"
                  width="50px"
                  height="50px"
                  @click="showImg(pic.url)"
                  style="margin: 5px;"
                >
              </div>
              <span v-if="!['upload_death_picture', 'upload_video'].includes(history.operateType)">备注：{{ history.operateContent }}</span>
            </a-space>
          </div>
          <div v-else-if="history.operateType == 'upload_emcee_draft'">
            <a-space>
              <span>上传司仪稿：</span>
              <a v-if="history.draft !== ''" @click="showDraft(history.draft)" style="margin-top:5px;">点击查看</a>
            </a-space>
          </div>
          <div v-else>
            <p>{{ history.operateContent }}</p>
          </div>
        </a-timeline-item>
      </a-timeline>
      <a-empty v-else />
    </a-spin>
    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :image.sync="previewImage"
    />

    <preview-video
      :visible.sync="previewVideoVisible"
      :video.sync="previewVideo"
    />

  </div>
</template>

<script>
import { findTaskOperationHistories } from '@/api/operation_history'

export default {
  name: 'TaskOperationHistoryList',
  props: {
    orderId: {
      type: Number,
      required: true
    },
    referenceId: {
      type: Number,
      required: true
    },
    height: {
      type: String,
      default: '400px'
    }
  },
  components: {
    PreviewImage: () => import('@/components/PreviewImage'),
    PreviewVideo: () => import('@/components/PreviewVideo')
  },
  data() {
    return {
      loading: true,
      data: [],
      taskOperationHistory: {},
      previewVisible: false,
      previewImage: '',
      previewVideoVisible: false,
      previewVideo: '',
      isShowDraftModal: false,
      draftContent: ''
    }
  },
  created() {
    this.fetchData()
  },
  filters: {
    operatorNameFilter(value) {
      if (value.length > 10) {
        return value.substring(0, 10) + '...'
      }
      return value
    }
  },
  methods: {
    showImg(pic) {
      this.previewVisible = true
      this.previewImage = pic
    },

    showVideo(url) {
      this.previewVideoVisible = true
      this.previewVideo = url
    },

    showDraft(draft) {
      window.open(draft, '_blank')
    },

    fetchData() {
      this.loading = true
      findTaskOperationHistories({ order_id: this.orderId, task_id: this.referenceId }).then((res) => {
        if (res.code === '0') {
          this.taskOperationHistory = res.data
          if (this.taskOperationHistory.nodeList) {
            this.data = res.data.nodeList
          }
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.scroll-box {
  overflow-y: auto;
  padding-top: 5px;
  padding-right: 10px;
}

.video {
  border: 1px solid #a7b6be36;
  padding: 5px;
  margin: 5px;
}
</style>
